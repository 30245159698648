<template lang="">
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Autorizações</template>
        <template #content>
          <div>
            <DataTable
              class="p-datatable-sm"
              sortField="razaoSocial"
              :loading="loading"
              :sortOrder="1"
              :value="listAutorizacoes"
              stripedRows
              responsiveLayout="stack">
              <template #empty> Nenhuma consignataria encontrada. </template>
              <template #loading> Carregando. Por favor aguarde. </template>
              <Column
                field="consignatariaRazaoSocial"
                header="Razão Social"></Column>
              <Column field="cnpj" header="CNPJ"></Column>
              <Column field="ativo" header="Autorização">
                <template #body="{ data }">
                  <Button
                    v-if="data.ativo"
                    label="AUTORIZADO"
                    icon="pi pi-check"
                    class="p-button-success md:col-12"
                    @click="cancelarAutorizacao(data)" />
                  <Button
                    v-else
                    label="NÃO AUTORIZADO"
                    icon="pi pi-times"
                    class="p-button-danger md:col-12"
                    @click="salvarAutorizacao(data)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import AutorizcaoPortabilidadeService from '@/service/AutorizacaoPortabilidadeService'

export default {
  data() {
    return {
      loading: true,
      listAutorizacoes: [],
      autorizacaoPortabilidade: {},
    }
  },

  created() {
    this.autorizacaoPortabilidadeService = new AutorizcaoPortabilidadeService(
      this.$http,
    )
  },
  mounted() {
    this.carregarAutorizacoes()
  },

  methods: {
    carregarAutorizacoes() {
      this.autorizacaoPortabilidadeService
        .listarConsignatariasComAutorizacao(123978)
        .then((res) => {
          this.listAutorizacoes = res
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    salvarAutorizacao(data) {
      this.atribuirDados(data)
      this.$confirm.require({
        message:
          'Ao confirmar, você autoriza o consignatário realizar a portabilidade e consultar todas as consignações registradas. Gostaria de prosseguir com essa autorização?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.autorizacaoPortabilidadeService
            .salvar(this.autorizacaoPortabilidade)
            .then(() => {
              this.carregarAutorizacoes()
            })
            .catch((err) => {
              this.$toast.add({
                severity: 'error',
                summary: err.response.data.message,
                life: 10000,
              })
            })
        },
        reject: () => {
          this.$confirm.close()
        },
      })
    },

    cancelarAutorizacao(data) {
      this.atribuirDados(data)

      this.autorizacaoPortabilidadeService
        .cancelarAcesso(this.autorizacaoPortabilidade)
        .then((res) => {
          console.log(res)
          this.carregarAutorizacoes()
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    atribuirDados(data) {
      this.autorizacaoPortabilidade.servidorId = data.servidorId
      this.autorizacaoPortabilidade.consignatariaId = data.consignatariaId
      this.autorizacaoPortabilidade.consignatariaRazaoSocial =
        data.consignatariaRazaoSocial
      this.autorizacaoPortabilidade.cnpj = data.cnpj
      this.autorizacaoPortabilidade.ativo = data.ativo
    },
  },
}
</script>
<style lang=""></style>
